// Use this for any shared settings that cross environment bounds.
// TODO: Setup explicit permissions if applicable to your Frontend web application.
// See https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth#Permissions for details
export const permissions = {
    defaultRedirectUrl: '/',
    defaultPermPrefix: 'your_app_prefix',
    routes: [
        ['/', 'read:assets']
    ]
};
export const appName = 'Angular Empty Box';
