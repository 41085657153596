<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<div ngClass.gt-sm="app-container-desktop" ngClass.lt-md="app-container-mobile" fxLayout="column" fxFill>
  <!--
   TODO:Replace Empty box name with the name of your application
   -->

  <zui-header [logoTitle]="title" >

  </zui-header>
  <div class="content-container" fxFlex>
    <router-outlet>
      <!--
      TODO: Set progress spinner size according to what you need
      -->
      <zui-progress-spinner
              *ngIf=""
              class="progress-spinner"
              color="accent"
              diameter=512
      ></zui-progress-spinner>
    </router-outlet>
  </div>
  <zui-footer>

  </zui-footer>
</div>

